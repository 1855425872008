.vertical-timeline::before {
  display: none;
}

.vertical-timeline {
  height: 100vh;
}

.vertical-timeline-element {
  margin: 0px;
}

.vertical-timeline-element:last-child {
  padding-bottom: 60px;
}

.vertical-timeline-element-icon img {
  border-radius: 20%;
  padding: 0px;
  margin-left: 20px;
  margin-right: -20px;
}

@media screen and (max-width: 767px) {
  .vertical-timeline {
    padding: 80px 0;
  }

  .vertical-timeline-element {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1170px) {
  .timeline-content {
    padding: 20px 20px 10px;
    border: 1px solid #e5ddd7;
  }
  .vertical-timeline-element-icon img {
    margin-left: 0px;
    margin-right: 0px;
    padding: 6px;
  }
}

/* #7b3a0b
#52290c
#412720
#705703
#7b881e
#8c3a00 */
